<template>
  <div class="home_top">
    <div class="inner">
      <img class="left_bg" src="@/assets/images/loanImg/left_bg.png" alt="">
      <div class="left_item">
        <h3> <br> RapidEfectivo！</h3>
        <p>RapidEfectivo is your one-stop loan platform.</p>
        <div>
          <div class="downloannow" @click="goDownloan()">Descargar APP</div>
          <!-- <a href="">
            <img src="@/assets/images/loanImg/download_now.png" alt="">
          </a> -->
          <!-- <img src="@/assets/images/loanImg/Google-play.png" alt=""> -->
        </div>
      </div>
      <div class="right_item">
        <img src="@/assets/images/loanImg/top_app.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goDownloan() {
      window.location.href = 'https://aws-logs-780801304422-ap-south-1.s3.ap-south-1.amazonaws.com/RapidEfectivo1.0.1.apk'
    }
  }
}
</script>

<style lang="scss" scoped>
.home_top {
  min-width: 1100px;
  height: 834px;
  background: #fff;
  position: relative;
  background: url("~@/assets/images/loanImg/top_bg.png") no-repeat top right;
  .inner {
    width: 1100px;
    margin: 0 auto;
    padding-top: 120px;
    .left_bg{
      width: 140px;
      // height: 200px;
      position: absolute;
      left: 0;
      top: 200px;
      z-index: -1;
    }
    .left_item {
      float: left;
      padding-top: 20px;
      h3 {
        font-size: 36px;
        color: #333333;
        font-weight: 700;
        margin-bottom: 40px;
      }
      p {
        font-size: 16px;
        color: #666666;
        margin-bottom: 100px;
      }
      div {
        img {
          width: 202px;
          height: 59px;
          margin-right: 20px;
        }
      }
    }
    .right_item {
      float: right;
      img {
        width: 650px;
      }
    }
  }
  .downloannow{
    width: 191px;
    height: 56px;
    background: linear-gradient(180deg, #8052FE 0%, #8052FE 100%);
    border-radius: 50px 50px 50px 50px;
    opacity: 1;
    line-height: 56px;
    text-align: center;
    font-size: 18px;
    color: white;
  }
}
</style>
